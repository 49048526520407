import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";

export const sendToken = (email) => (dispatch) => {
  axios
    .post("/api/users/sendToken", { email: email })
    .then((res) => {
      console.log(res.data);
    })
    // re-direct to login on successful register
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    );
};

export const storeNewPassword = (password, userId, token) => (dispatch) => {
  // store.dispatch(showLoading())
  axios
    .post("store-password", {
      //node.js api to send the new password to
      userId: userId,
      password: password,
      token: token,
    })
    .then((response) => {
      // store.dispatch(hideLoading())
      if (response.data.success) {
        console.log(response.data.success);
        // notification.success(response.data.message);
        // return true;
      } else {
        // notification.error(response.data.message);
        console.log(response.data.success);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/register", userData)
    .then((res) => {
      history.push("/login");
      console.log("RESULTAT //// >" + res);
    })
    // re-direct to login on successful register
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const editUser = (userData) => (dispatch) => {
  console.log(userData);
  axios
    .post("/api/users/edit-profil", userData)
    .then((res) => {
      dispatch(setCurrentUser(res.data));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
/////////////////////////////
export const editEmail = (userData) => (dispatch) => {
  console.log(userData);
  axios
    .post("/api/users/edit-email", userData)
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
