import React, { useEffect } from "react";
import { initGA } from "./ga-utils";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./private-route/PrivateRoute";
import "./App.scss";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
// import GererAnnonces from "./views/site/admin/gererAnnonces";
import logo_fidari from "./assets/img/logo-fidari.png";

// import { Helmet, HelmetProvider } from "react-helmet-async";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";

function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

// if (getCookieConsentValue("CookieConsent") === "false") {
//   // console.log("delete all cookies");
//   Cookies.remove("_fbp");
//   Cookies.remove("_ga_NTSXNZ1LDT");
//   Cookies.remove("_ga");
//   delete_cookie("_fbp");
//   delete_cookie("_ga_NTSXNZ1LDT");
//   delete_cookie("_ga");
// }

var Loader = require("react-loader");

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <img alt="FI-DARI.tn" className="loading-logo" src={logo_fidari}></img>
    <Loader
      type="Bars"
      // color="#00BFFF"
      className="info"
      height={5}
      width={5}
    ></Loader>
  </div>
);

// Pages
const Login = React.lazy(() => import("./views/auth/Login"));
const Register = React.lazy(() => import("./views/auth/Register"));

const DefaultLayout = React.lazy(() =>
  import("./views/site/layout/Backoffice")
);
const UneAnnonce = React.lazy(() => import("./views/Annonces/UneAnnonce"));
const AnnonceProfil = React.lazy(() =>
  import("./views/Annonces/AnnonceProfil")
);
const MesDemandes = React.lazy(() => import("./views/Demandes/MesDemandes"));
const Landing = React.lazy(() => import("./views/site/layout/Landing"));
const Contact = React.lazy(() => import("./views/site/layout/Contact"));
const Apropos = React.lazy(() => import("./views/site/layout/Apropos"));
const Blog = React.lazy(() => import("./views/site/layout/Blog"));
const Article = React.lazy(() => import("./views/site/layout/Article"));
const Cgu = React.lazy(() => import("./views/site/layout/Cgu"));
const CguAR = React.lazy(() => import("./views/site/layout/CguTN"));
const Privacy = React.lazy(() => import("./views/site/layout/Privacy"));
const PrivacyAR = React.lazy(() => import("./views/site/layout/PrivacyTN"));
const ResultPage = React.lazy(() =>
  import("./views/site/search-bar/ResultPage")
);
const selectedAnnonce = React.lazy(() =>
  import("./views/site/search-bar/selectedAnnonce")
);
const UnProjet = React.lazy(() => import("./views/Annonces/UnProjet"));
const UnBien = React.lazy(() => import("./views/Annonces/UnBien"));
const UnCredit = React.lazy(() => import("./views/Annonces/UnCredit"));
const ResetPwd = React.lazy(() => import("./views/auth/ResetPwd"));
const NewPasswordContainer = React.lazy(() =>
  import("./views/auth/NewPasswordContainer")
);
const Activate = React.lazy(() => import("./views/auth/Activate"));
const ChangeMail = React.lazy(() => import("./views/auth/ChangeMail"));
const EditAnnonce = React.lazy(() => import("./views/Annonces/EditAnnonce"));
const AjouterAnnonce = React.lazy(() =>
  import("./views/Annonces/AjouterAnnonce")
);
const AnnoncesList = React.lazy(() => import("./views/Annonces/MesAnnonces"));
const MesRDV = React.lazy(() => import("./views/Demandes/MesRDV"));

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000;
  // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

function App() {
  const handleAcceptCookie = () => {
    console.log(process.env.REACT_APP_PROD);
    console.log(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove facebook cookies
    Cookies.remove("_fbp");
    //remove google analytics cookies
    Cookies.remove("_ga_NTSXNZ1LDT");
    Cookies.remove("_ga");
    delete_cookie("_fbp");
    delete_cookie("_ga_NTSXNZ1LDT");
    delete_cookie("_ga");
  };

  useEffect(() => {
    console.log("useEffect");
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      console.log(isConsent);

      handleAcceptCookie();
    }
  }, []);
  return (
    <Provider store={store}>
      <CookieConsent
        enableDeclineButton
        flipButtons
        buttonText="Accepter"
        declineButtonText="Refuser"
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        Sur ce site, nous utilisons les cookies{" "}
        <span style={{ padding: "10px" }}>
          <img
            alt=""
            src="https://favicons.axept.io/favicons?domain=analytics.google.com"
          />
        </span>
        <span
          style={{ padding: "10px" }}
          title="pour mesurer notre audience, entretenir la relation avec vous et vous adresser de temps à autre du contenu qualitif"
        >
          Google Analytics
        </span>
        <span style={{ padding: "10px" }}>
          <img
            alt=""
            src="https://favicons.axept.io/favicons?domain=facebook.com"
          />
        </span>
        <span
          style={{ padding: "10px" }}
          title="ce petit bout de code que nous fourni Facebook nous permet de poursuivre nos échanges dans votre fil d’actualité ainsi que de la publicité"
        >
          Facebook Pixel
        </span>{" "}
        En cliquant sur "Accepter", vous consentez à l'utilisation de tous les
        cookies. Cependant, vous pouvez cliquer sur "Refuser" pour ne pas
        recevoir les cookies de suivi statistique et publicitaire.
      </CookieConsent>
      <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />

            <Route exact path="/ResetPassword" component={ResetPwd} />
            <Route exact path="/" component={Landing} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/apropos" component={Apropos} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:id" component={Article} />
            <Route exact path="/cgu" component={Cgu} />
            <Route exact path="/cgu-ar" component={CguAR} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/privacy-ar" component={PrivacyAR} />
            <Route exact path="/more/:id" component={UneAnnonce} />
            <Route exact path="/detail/:id" component={UnProjet} />
            <Route exact path="/bien/:id" component={UnBien} />
            <Route exact path="/all-annonces/:id" component={AnnonceProfil} />
            <Route exact path="/simulez-votre-credit" component={UnCredit} />

            <Route
              exact
              path="/reset/:userId/:token"
              component={NewPasswordContainer}
            />
            <Route
              exact
              path="/activate/:userId/:token/:f_name/:l_name/:email"
              component={Activate}
            />
            <Route
              exact
              path="/changemail/:userId/:token/:email/:newmail"
              component={ChangeMail}
            />
            <Route exact path="/search" component={ResultPage} />
            <Route
              exact
              path="/SelectionAnnoncesImmo"
              component={selectedAnnonce}
            />
            <Route
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />

            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
            <PrivateRoute path="/edit/:slug" component={EditAnnonce} />
            <PrivateRoute
              path="/annonces/mes-annonces"
              component={AnnoncesList}
            />
            <PrivateRoute
              path="/annonces/ajouter-annonce"
              component={AjouterAnnonce}
            />
            <PrivateRoute path="/mes-demandes" component={MesDemandes} />
            <PrivateRoute path="/mes-rdv" component={MesRDV} />
          </Switch>
        </React.Suspense>
      </Router>
    </Provider>
  );
}

export default App;
