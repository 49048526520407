import { 
  // GET_ERRORS,
   SET_SIMULATION } from "../actions/types";
    const initialState = {
      payload:"",  
    };
     
  export default function simulateMortgage(state = initialState, action) {
      switch (action.type) {
        case SET_SIMULATION:
          return {
            ...state,
            payload: action.payload  
          };
        default:
          return state;
      }
  }