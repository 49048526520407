import {
  SET_SEARCH,LOADING_SEARCH,
  } from "../actions/types.js";
  const initialState = {
    searchInput : '',
    loading:false,
    searchisnotempty : false, 
    payload: {},

    pager : [],


    mapload: {}
  };
   
export default function searchfoo(state = initialState, action) {
    switch (action.type) {
      case SET_SEARCH:
        return {
          ...state,
          searchInput : action.searchInput,
          searchisnotempty : action.searchisnotempty,
          payload: action.payload,

          pager: action.pager,


          mapload: action.mapload,
          loading:action.loading
        };
        case LOADING_SEARCH:
          return {
            ...state,
            loading : action.loading,
             };
      default:
        return state;
    }
}